import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrButton, ElrInput, ElrTextAreaInput } from 'ui-components';
import { ElrInputWithIcon } from 'ui-components/components/ElrInput';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import { ElrUploadV2 } from 'ui-components/components/ElrUpload';
import {
  BackIconWonWithBG,
  CalendarIcon,
  DropdownIcon,
  PlusIcon,
} from '../../SvgAssets';

const Events = () => {
  console.log('events');
  return (
    <div className="pt-16">
      <section className="flex gap gap-x-2 ml-32">
        <BackIconWonWithBG />
        <p className="self-center">Back</p>
      </section>
      <div className="flex justify-center">
        <div className="pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="text-2xl text-elr-black leading-7 font-normal mb-2">
              Event Information
            </h1>
            <p className="text-elr-black-400 text-lg leading-5">
              Kindly fill in your event details
            </p>
          </header>
          <div className="bg-elr-white-400 px-10 py-8 w-full mx-auto mb-10">
            <div>
              <ElrInput
                onChange={() => {}}
                className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3"
                placeholder="Event Name"
              />
              <ElrInput
                onChange={() => {}}
                className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3"
                placeholder="Event Location"
              />
              <ElrTextAreaInput
                onChange={() => {}}
                className="border-none rounded-none bg-elr-gray outline-none mb-1.5"
                placeholder="Event Description"
              />
              <div className="flex justify-between mb-3 gap-4 md:flex-nowrap flex-wrap">
                <ElrInputWithIcon
                  parentClassName="w-full md:w-1/2"
                  className="w-full h-12"
                  icon={<CalendarIcon />}
                  placeHolder="Event Date"
                />
                <ElrInputWithIcon
                  className="w-full h-12"
                  parentClassName="w-full md:w-1/2"
                  icon={<DropdownIcon />}
                  placeHolder="Event Time"
                />
              </div>
              <div className="mb-5">
                <h3 className="mb-1.5">Event Banner (optional)</h3>
                <ElrUploadV2 tag={''} onUploaded={() => {}} />
              </div>
              <section>
                <div>
                  <h3 className="mb-1.5">Ticket Type</h3>
                  <ElrInput
                    onChange={() => {}}
                    className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3"
                    placeholder="Enter Ticket Type. e.g VIP"
                  />

                  <div className="flex items-center mt-2 mb-3 h-12">
                    <span className="inline-flex items-center h-12 px-3 text-sm bg-elr-deep-gray border-none">
                      NGN
                    </span>
                    <ElrInput
                      onChange={() => {}}
                      className="border-none rounded-none bg-elr-gray outline-none !h-12"
                      placeholder="Ticket Price"
                    />
                  </div>
                </div>
                <ElrInput
                  onChange={() => {}}
                  className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3"
                  placeholder="Available Quantity"
                />
                <ElrInputWithIcon
                  className="h-12 w-full"
                  parentClassName="w-full"
                  icon={<CalendarIcon />}
                  placeHolder="Sales Deadline"
                />

                <div className="mt-3">
                  <h3 className="mb-1.5">Who pays payment charges?</h3>

                  <div className="flex gap gap-x-6 text-elr-elr-black-300">
                    <ElrRadio
                      checked={false}
                      radioClassName="bg-elr-black-300"
                      label="I Pay"
                      labelClassName="text-elr-black-300 before:align-text-top"
                    />
                    <ElrRadio
                      checked={false}
                      radioClassName="bg-elr-black-300"
                      labelClassName="text-elr-black-300 before:align-text-top"
                      label="Customer Pays"
                    />
                  </div>
                </div>
                <div className="bg-elr-gray-neutral-200 border-2 border-elr-gray-neutral-200 my-4" />
                <div className="flex justify-end gap gap-x-2 items-center text-elr-purple">
                  <PlusIcon />
                  <p>Add Another Ticket Type</p>
                </div>
              </section>
            </div>
            <div className="mt-14 mx-auto text-center">
              <nav>
                <Link
                  to={`/${COMMERCE}/${privateRoutes.ecommerce}/rs9to/${privateRoutes.eventsPreview}`}
                >
                  <ElrButton
                    text={'Preview'}
                    className="bg-elr-black w-full text-elr-white-400 rounded-sm"
                  />
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Events;
