import {
  httpCloseEvent,
  httpGetEvent,
  httpGetEventTicketLeft,
  httpGetListSoldTickets,
  httpGetTicketSales,
  httpPurchaseTicket,
  httpToggleTicket,
} from 'app/api/event';
import {
  EventDataInterface,
  ListSoldTicketsParams,
  PurchaseTicketPayload,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from 'app/api/types';
import { EventSession } from 'app/components/events/utils';
import { useCallback, useEffect, useState } from 'react';

export const useManageTicket = (
  eventUUId: string,
  eventId?: string,
  ticket?: EventDataInterface['tickets'][number]
) => {
  const [updatedTicket, setUpdatedTicket] = useState<
    | (EventDataInterface['tickets'][number] & { quantityRequested: number })
    | null
  >(null);

  const getUpdatedTicket = () => {
    if (!ticket) return;
    if (!EventSession(eventUUId).getSession()?.tickets?.[ticket.id]) {
      EventSession(eventUUId).setSession({
        eventId,
        tickets: {
          ...(EventSession(eventUUId).getSession()?.tickets || {}),
          [ticket.id]: { ...ticket, quantityRequested: 0 },
        },
      });
    }
    setUpdatedTicket(
      EventSession(eventUUId).getSession()?.tickets?.[ticket.id]
    );
  };

  const setQuantity = (quantity: number) => {
    if (!ticket) return;
    EventSession(eventUUId).setSession({
      eventId,
      tickets: {
        ...(EventSession(eventUUId).getSession()?.tickets || {}),
        [ticket.id]: { ...ticket, quantityRequested: quantity },
      },
    });
    getUpdatedTicket();
  };

  const getTotalQuantities = () => {
    const tickets: {
      [key: string]: EventDataInterface['tickets'][number] & {
        quantityRequested: number;
      };
    } = EventSession(eventUUId).getSession()?.tickets || {};
    const totalQuantityRequested = Object.values(tickets).reduce(
      (acc, t) => acc + (t?.quantityRequested || 0),
      0
    );
    return totalQuantityRequested;
  };

  const getRequestedTickets = () => {
    const tickets: (EventDataInterface['tickets'][number] & {
      quantityRequested: number;
    })[] = EventSession(eventUUId).getSession()?.tickets
      ? Object.values(EventSession(eventUUId).getSession()?.tickets)
      : [];
    return { tickets, eventId: EventSession(eventUUId).getSession()?.eventId };
  };

  useEffect(() => {
    getUpdatedTicket();
  }, []);

  return {
    quantity: updatedTicket?.quantityRequested || 0,
    setQuantity,
    getTotalQuantities,
    getRequestedTickets,
  };
};

export const usePurchaseTicket = () => {
  const purchaseTicket = useCallback(
    async (ticketData: PurchaseTicketPayload) => {
      try {
        const response = await httpPurchaseTicket(ticketData);
        return response.data; // Adjust based on the response structure
      } catch (error) {
        console.error('Error purchasing ticket:', error);
        throw error; // Rethrow the error for handling in the component
      }
    },
    []
  );

  return { purchaseTicket };
};

export const useFetchEvent = (
  eventUUId: string,
  setEvent: (event: EventDataInterface | null) => void
) => {
  const getEvent = useCallback(async () => {
    const ev = await httpGetEvent({ eventUUId });
    if (ev) setEvent(ev);
  }, [eventUUId, setEvent]);

  return { getEvent };
};

export const useCloseEvent = () => {
  const closeEvent = useCallback(async (eventId: string, roleId: string) => {
    const ev = await httpCloseEvent({ eventId, roleId });
    return ev;
  }, []);

  return { closeEvent };
};

export const useFetchTicketSales = () => {
  const fetchTicketSales = useCallback(
    async (
      eventId: string,
      setTicketSales: (sales: TicketSalesDataInterface[] | null) => void
    ) => {
      try {
        const sales = await httpGetTicketSales({ eventId });
        setTicketSales(sales);
      } catch (error) {
        setTicketSales(null);
      }
    },
    []
  );

  return { fetchTicketSales };
};

export const useToggleTicket = () => {
  const toggleTicket = useCallback(
    async (eventId: string, ticketType: string, ticketStatus: boolean) => {
      const response = await httpToggleTicket(
        eventId,
        ticketType,
        ticketStatus
      );
      return response.ok;
    },
    []
  );

  return { toggleTicket };
};

export const useListSoldTickets = () => {
  const listSoldTickets = useCallback(
    async (
      params: ListSoldTicketsParams,
      setSoldTickets: (soldTickets: SoldTicketsResponse[]) => void
    ) => {
      const { eventId, page, limit } = params;

      if (!eventId) {
        setSoldTickets([]);
        return;
      }

      try {
        const soldTickets = await httpGetListSoldTickets({
          eventId,
          page,
          limit,
        });
        setSoldTickets(soldTickets);
      } catch (error) {
        console.error('Error listing sold tickets:', error);
        setSoldTickets([]);
      }
    },
    []
  );

  return { listSoldTickets };
};

interface GetEventTicketLeftParams {
  eventId: string;
  tickets: Array<{ ticketType: string; quantity: number }>;
}

export const useGetEventTicketLeft = () => {
  const getEventTicketLeft = useCallback(
    async (params: GetEventTicketLeftParams) => {
      const response = await httpGetEventTicketLeft(params);
      return response;
    },
    []
  );

  return { getEventTicketLeft };
};
