import { EventDataInterface } from 'app/api/types';
import { useFetchEvent, useManageTicket } from 'app/hooks/event';
import { publicRoutes } from 'app/utilities/routes';
import appleStoreBtn from 'assets/images/app-store-btn-white.svg';
import iphones from 'assets/images/event-iphones.svg';
import googlePlay from 'assets/images/google-play-btn-white.svg';
import MinusIcon from 'assets/images/minus-black.svg';
import PlusIcon from 'assets/images/plus-black.svg';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ElrButton } from 'ui-components';
import { Footer, Header } from './utils';

export const EventHome: React.FC = () => {
  const [event, setEvent] = useState<EventDataInterface | null>(null);
  const [totalTicketsRequested, setTotalTicketsRequested] = useState(0);
  const params = useParams();
  const { eventUUId } = params;

  const { getEvent } = useFetchEvent(eventUUId as string, setEvent);

  useEffect(() => {
    getEvent();
  }, []);

  const navigation = useNavigate();

  if (!event) return <div />;

  return (
    <>
      <Header eventId={event.uuid} />
      <main className="min-h-full px-4 pt-10 pb-40 bg-white md:pt-14 xl:px-48 lg:px-32 md:px-20">
        <section className="flex flex-col-reverse items-center justify-center text-white bg-contain rounded gap-y-9 pt-7 bg-event-landing-bg md:flex-row gap-x-10">
          <div>
            <img src={iphones} alt="iphones" />
          </div>

          <div>
            <h2 className="text-2xl font-bold">
              Get exclusive discount on this <br /> event ticket
            </h2>
            <p className="mt-3 text-lg text-white opacity-90">
              Download Errandlr app to unlock special <br /> discounts on
              events. Don&apos;t miss out.
            </p>
            <div className="relative flex items-center justify-center gap-6 mt-5 md:mt-8">
              <a
                target="_blank"
                href="https://apps.apple.com/app/errandlr/id6499235011"
                rel="noreferrer"
              >
                <img
                  src={appleStoreBtn}
                  alt=""
                  className="w-40 cursor-pointer"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share"
                rel="noreferrer"
              >
                <img src={googlePlay} alt="" className="w-40 cursor-pointer" />
              </a>
            </div>
          </div>
        </section>
        <section className="h-full mt-6">
          <img
            src={event.bannerUrl}
            alt="iphones"
            className="object-contain w-full h-80 md:h-eventBanner"
          />
        </section>
        <section className="mt-7">
          <h2 className="text-3xl font-medium">{event.name}</h2>
          <p className="mt-2 text-opacity-50 text-elr-black">
            {event.description}
          </p>
          <h5 className="mt-5 text-lg font-medium">Event Venue</h5>
          <p className="mt-1 text-opacity-50 text-elr-black">
            {event.location}
          </p>

          <h5 className="mt-6 text-lg font-medium">Event Date & Time</h5>
          <p className="mt-1 text-opacity-50 text-elr-black">
            {DateTime.fromISO(event.eventDate).toFormat(
              'MMMM dd, yyyy hh:mm a'
            )}
          </p>
        </section>

        {event.status === 'open' ? (
          <section className="mt-9">
            <h4 className="text-xl font-medium">Tickets</h4>
            <div className="py-5 mt-4 rounded-lg bg-elr-gray-100">
              {event.tickets.map((ticket) =>
                ticket.active ? (
                  <Ticket
                    key={ticket.id}
                    setTotalTicketsRequested={setTotalTicketsRequested}
                    ticket={ticket}
                    eventId={event.id}
                    eventUUId={eventUUId as string}
                  />
                ) : null
              )}

              <div className="flex items-center justify-end px-5 mt-8 md:px-20">
                <ElrButton
                  disabled={totalTicketsRequested === 0}
                  onClick={() =>
                    navigation(
                      `${publicRoutes.event}/${eventUUId}/contact-information`
                    )
                  }
                  className="text-white w-full md:w-auto !rounded bg-elr-black px-10"
                  text="Buy Ticket"
                />
              </div>
            </div>
          </section>
        ) : (
          <section className="mt-9">
            <h4 className="text-xl font-medium">Tickets</h4>
            <ElrButton
              className="text-elr-error w-full md:w-auto !rounded bg-elr-gray-100 px-10 cursor-not-allowed"
              text="No Longer Available"
            />
          </section>
        )}
      </main>
      <Footer />
    </>
  );
};

const Ticket = ({
  ticket,
  eventId,
  eventUUId,
  setTotalTicketsRequested,
}: {
  ticket: EventDataInterface['tickets'][number];
  eventId: string;
  eventUUId: string;
  setTotalTicketsRequested: (total: number) => void;
}) => {
  const { quantity, setQuantity, getTotalQuantities } = useManageTicket(
    eventUUId,
    eventId,
    ticket
  );

  useEffect(() => {
    setTotalTicketsRequested(getTotalQuantities());
  }, [quantity]);

  const increment = () =>
    quantity < ticket.quantity && setQuantity(quantity + 1);
  const decrement = () => quantity > 0 && setQuantity(quantity - 1);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuantity(Number(e.target.value));

  return (
    <div className="flex flex-col items-start px-5 py-5 border-b md:px-20 md:justify-between md:items-center md:flex-row gap-y-4 border-b-elr-gray-200 last:border-b-0">
      <div>
        <h4 className="text-lg font-medium capitalize">{ticket.type}</h4>
        <p className="mt-1 text-sm text-opacity-60 text-elr-black">
          {ticket.quantity} Available
        </p>
      </div>
      <div className="flex items-center">
        <p className="text-base font-medium md:text-lg">
          NGN&nbsp;
          {Intl.NumberFormat('en-NG', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(ticket.price)}
        </p>
      </div>
      {ticket.quantity ? (
        <div className="flex justify-between px-5 py-2.5 bg-white rounded md:items-center gap-x-3">
          <button className="p-1" type="button" onClick={decrement}>
            <img src={MinusIcon} alt="minus" />
          </button>
          <input
            type="number"
            value={quantity}
            onChange={handleChange}
            className="w-20 h-10 font-bold text-center bg-white border-none rounded-lg"
          />
          <button className="p-1" type="button" onClick={increment}>
            <img src={PlusIcon} alt="plus" />
          </button>
        </div>
      ) : (
        <div className="flex justify-between px-5 py-2.5 bg-white rounded md:items-center gap-x-3 w-44">
          <button
            type="button"
            disabled
            className="p-1 text-elr-error text-center mx-auto"
          >
            Sold Out
          </button>
        </div>
      )}
    </div>
  );
};
