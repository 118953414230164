import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import {
  BasketIconWonWithBG,
  CalendarIconWonWithBG,
  CutleryIconWonWithBG,
  LeafIconWonWithBG,
  WearIconWonWithBG,
} from '../SvgAssets';

const EcommerceInitial = () => {
  console.log(
    'first check if user already has an outlet type, then redirect accordingly'
  );

  return (
    <div className="flex justify-center">
      <div className="pt-20  mb-32">
        <header className="mb-6 text-center">
          <h1 className="text-2xl text-elr-black leading-7 font-normal mb-2">
            What do you want to sell?
          </h1>
          <p className="text-elr-black-400 text-lg leading-5">
            Select the option that applies to you
          </p>
        </header>

        <div className="grid grid-cols-column-two gap-4">
          <div className="bg-elr-white-400 py-5 px-6">
            <div>
              <div className="float-end">
                <ElrRadio onChange={() => {}} checked={false} />
              </div>
              <LeafIconWonWithBG />
              <div className="mb-6" />
              <p className="text-lg text-elr-black leading-5">
                Market Products
              </p>
              <p className="text-base text-elr-black-300">
                e.g foodstuffs items in bulk
              </p>
            </div>
          </div>
          <div className="bg-elr-white-400 py-5 px-6">
            <div>
              <div className="float-end">
                <ElrRadio onChange={() => {}} checked={false} />
              </div>
              <CutleryIconWonWithBG />
              <div className="mb-6" />

              <p className="text-lg text-elr-black leading-5">Cooked Meals</p>
              <p className="text-base text-elr-black-300">
                e.g Restaurant Meals and Dishes
              </p>
            </div>
          </div>
          <div className="bg-elr-white-400 py-5 px-6">
            <div>
              <div className="float-end">
                <ElrRadio onChange={() => {}} checked={false} />
              </div>
              <BasketIconWonWithBG />
              <div className="mb-6" />

              <p className="text-lg text-elr-black leading-5">
                Essential Items
              </p>
              <p className="text-base text-elr-black-300">
                e.g Hair care products, Toiletries
              </p>
            </div>
          </div>
          <div className="bg-elr-white-400 py-5 px-6">
            <div>
              <div className="float-end">
                <ElrRadio onChange={() => {}} checked={false} />
              </div>
              <WearIconWonWithBG />
              <div className="mb-6" />

              <p className="text-lg text-elr-black leading-5">Lifestyle</p>
              <p className="text-base text-elr-black-300">
                e.g clothes, shoes, accessories
              </p>
            </div>
          </div>
          <div className="bg-elr-white-400 py-5 px-6">
            <div>
              <div className="float-end">
                <ElrRadio onChange={() => {}} checked={false} />
              </div>
              <CalendarIconWonWithBG />
              <div className="mb-6" />

              <p className="text-lg text-elr-black leading-5">Events</p>
              <p className="text-base text-elr-black-300">
                e.g tickets to a rave, conferences, hangout
              </p>
            </div>
          </div>
        </div>
        <div className="mt-14 mx-auto text-center">
          <nav>
            <Link to="events">
              <ElrButton
                text={'Proceed'}
                className="bg-elr-black text-elr-white-400 rounded-sm"
              />
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default EcommerceInitial;
