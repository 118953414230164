import MenuIcon from '@material-ui/icons/Menu';
import { useLogin, useLogout } from 'app/hooks/auth';
import {
  currentTimeLogo,
  mobileMenuIconStyle,
} from 'app/modules/landing/components/ClassUtils';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HamburgerMenu } from './HamburgerMenu';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const NavBar: React.FC<Props> = ({ children, className = '' }) => (
  <div className={`${className} mx-5 lg:mx-16 pt-10`}>{children}</div>
);

export const RenderUnAuthMenuListLinks: React.FC<{
  containerClass: string;
}> = ({ containerClass }) => {
  const [sendLogin] = useLogin();

  return (
    <>
      <div onClick={() => sendLogin()} className={containerClass}>
        Login
      </div>
      <Link to={publicRoutes.registration} className={containerClass}>
        Sign up
      </Link>
    </>
  );
};

export const RenderAuthMenuListLinks: React.FC<{
  containerClassName: string;
}> = ({ containerClassName }) => {
  const [sendLogout] = useLogout();

  return (
    <>
      <Link to={privateRoutes.selectPortal} className={containerClassName}>
        Select Portal
      </Link>
      <div onClick={sendLogout} className={containerClassName}>
        Logout
      </div>
    </>
  );
};

interface TopNavProp {
  isDefault?: boolean;
}

export const TopNav: React.FC<TopNavProp> = ({ isDefault = true }) => {
  const navLinks = [
    { path: publicRoutes.estimate, label: 'Estimate' },
    { path: publicRoutes.track, label: 'Track Parcel' },
    { path: publicRoutes.aboutUs, label: 'About Us' },
    { path: 'for-business', label: 'For Business' },
    { path: publicRoutes.apiPage, label: 'API' },
  ];

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <NavBar className="flex items-center justify-between">
      <Link to="/">
        <img src={currentTimeLogo(isDefault)} alt="site logo" />
      </Link>

      <div className="flex items-center lg:hidden">
        <span
          className={mobileMenuIconStyle(isDefault)}
          onClick={toggleMobileMenu}
        >
          <MenuIcon />
        </span>
      </div>

      {showMenu && (
        <HamburgerMenu onClose={toggleMobileMenu} navLinks={navLinks} />
      )}

      <ul className="hidden lg:flex gap-11">
        {navLinks.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className="block p-1 cursor-pointer text-opacity-40"
          >
            {link.label}
          </Link>
        ))}
      </ul>

      <div className="hidden gap-3 lg:flex">
        <Link to={publicRoutes.login}>
          <button
            className="flex items-center px-8 py-2 bg-white rounded-full cursor-pointer"
            type="button"
          >
            Log In
          </button>
        </Link>
        <Link to={publicRoutes.registration}>
          <button
            className="flex items-center px-8 py-2 text-white rounded-full cursor-pointer bg-elr-black"
            type="button"
          >
            Sign Up
          </button>
        </Link>
      </div>
    </NavBar>
  );
};
