import React from 'react';

interface Props {
  checked: boolean;
  onChange?: () => void;
  label?: string;
  color?: string;
  index?: string;
  labelClassName?: string;
  radioClassName?: string;
}

export const ElrRadio: React.FC<Props> = ({
  onChange,
  checked,
  label = '',
  color = 'bg-elr-black',
  index,
  labelClassName,
  radioClassName,
}) => (
  <button
    type="button"
    className="flex items-center justify-center focus-within:border-opacity-60 cursor-pointer"
  >
    <input
      onClick={onChange}
      id={`elrRadio-${index}`}
      type="radio"
      className={`hidden ${radioClassName}`}
      defaultChecked={checked}
    />
    <label
      htmlFor={`elrRadio-${index}`}
      className={`${labelClassName} before:p-0.5 before:border-03 before:mr-1.5 before:rounded-full before:cursor-pointer before:inline-block before:w-4 before:h-4 before:bg-clip-content before:box-border ${
        checked ? `before:${color}` : ''
      }`}
    >
      {label}
    </label>
  </button>
);
