import { BackIconWonWithBG } from 'app/components/SvgAssets';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';

const EventPreview = () => {
  console.log('review');
  return (
    <div className="pt-16">
      <section className="flex gap gap-x-2 ml-32">
        <BackIconWonWithBG />
        <p className="self-center">Back</p>
      </section>
      <div className="flex justify-center">
        <div className="pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="text-2xl text-elr-black leading-7 font-normal mb-2">
              Event Preview
            </h1>
            <p className="text-elr-black-400 text-lg leading-5">
              Please confirm your event details
            </p>
          </header>
          <div className="bg-elr-white-400 px-10 py-8 w-full mb-10 mx-auto">
            <div>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Event Name</p>
                <p>Tech Hangout</p>
              </section>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Event Description</p>
                <p className="w-1/2">
                  Lorem ipsum dolor sit amet consectetur. Vitae pulvinar aliquet
                  duis fringilla sit praesent proin
                </p>
              </section>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Event Location</p>
                <p className="w-1/2">Ikeja city mall, Obafemi Awolowo Road</p>
              </section>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Event Date</p>
                <p className="w-1/2">09-09-2024</p>
              </section>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Ticket 1</p>
                <p className="w-1/2">Tech Hangout</p>
              </section>
              <section className="flex mb-8">
                <p className="w-1/2 text-elr-black-300">Ticket 2</p>
                <p className="w-1/2">Tech Hangout</p>
              </section>
            </div>
            <div className="mt-14 flex justify-between text-center">
              <nav>
                <Link
                  to={`/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.eventsPreview}`}
                >
                  <ElrButton
                    text={'Edit'}
                    className="bg-elr-black w-full text-elr-white-400 rounded-sm"
                  />
                </Link>
              </nav>
              <nav>
                <Link
                  to={`/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.eventsDashboard}`}
                >
                  <ElrButton
                    text={'Start Selling'}
                    className="bg-elr-black w-full text-elr-white-400 rounded-sm"
                  />
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPreview;
