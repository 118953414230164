import { Outlet } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import ProfileNavigationMenu from '../menu/ProfileNavigationMenu';
import TopNavigationMenu from '../menu/TopNavigationMenu';

const Ecommerce = () => {
  console.log('here');
  return (
    <>
      <ElrPageTitle title="Errandlr - Ecommerce" />
      <ProfileNavigationMenu>
        <TopNavigationMenu />
        <div className="bg-elr-gray">
          <Outlet />
        </div>
      </ProfileNavigationMenu>
    </>
  );
};

export default Ecommerce;
