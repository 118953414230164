import {
  EventDataInterface,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from 'app/api/types';
import {
  CopyDoubleIcon,
  DrawerIcon,
  InformationIcon,
  MoneyIcon,
  PlusIcon,
} from 'app/components/SvgAssets';
import {
  useCloseEvent,
  useFetchEvent,
  useFetchTicketSales,
  useListSoldTickets,
  useToggleTicket,
} from 'app/hooks/event';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { currencyFormatter } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ElrSwitch } from 'ui-components';
import {
  ElrButton,
  ElrButtonPreIcon,
  ElrDropdownButton,
} from 'ui-components/components/ElrButton';

const EventDashboard = () => {
  const params = useParams();
  const [event, setEvent] = useState<EventDataInterface | null>(null);
  const [ticketSales, setTicketSales] = useState<TicketSalesDataInterface[]>(
    []
  );
  const [soldTickets, setSoldTickets] = useState<SoldTicketsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { eventUUId } = params;
  const {
    accountInformation: { id: roleId },
  } = useGetProfile();

  const { getEvent } = useFetchEvent(eventUUId as string, setEvent);
  const { fetchTicketSales } = useFetchTicketSales();
  const { toggleTicket } = useToggleTicket();
  const { closeEvent } = useCloseEvent();
  const { listSoldTickets } = useListSoldTickets();

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (event) {
      fetchTicketSales(event.id, (sales) => {
        if (sales !== null) {
          setTicketSales(sales);
        }
      });
      listSoldTickets(
        { eventId: event.id, page: 1, limit: 100 },
        (fetchedSoldTickets) => {
          if (fetchedSoldTickets !== null) {
            setSoldTickets(fetchedSoldTickets);
          }
        }
      );
    }
  }, [event?.id, fetchTicketSales, listSoldTickets]);

  if (!event) return <div />;
  const eventIsClosed = event.status === 'closed';

  const eventLink = `${window.location.origin}${publicRoutes.event}/${event.uuid}`;
  const calculateTotalSalesAmount = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalAmountOfTicketSold,
      0
    );

  const calculateTotalTicketsSold = (
    businessticketSales: TicketSalesDataInterface[] = []
  ): number =>
    businessticketSales.reduce(
      (total, sale) => total + sale.totalQuantityofTicketSold,
      0
    );

  const handleCopy = () => {
    copyToClipboard(eventLink);
    toast.success('Event link copied to clipboard');
  };

  const formatExpiryDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleToggleTicket = async (
    ticketType: string,
    ticketStatus: boolean
  ) => {
    try {
      setIsLoading(true);
      await toggleTicket(event.id, ticketType, ticketStatus);
      getEvent();
    } catch (error) {
      console.error('Error toggling ticket:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseEvent = async () => {
    try {
      setIsLoading(true);
      await closeEvent(event.id, roleId);
      getEvent();
      toast.success('Event closed successfully');
    } catch (error) {
      toast.error('Error closing event');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-8 px-40 pb-8">
      <section className="flex justify-between pb-8">
        <div>
          <h3>Events</h3>
          <p className="text-elr-black-300">
            View and follow up with your events here.
          </p>
        </div>
        <div>
          <ElrButtonPreIcon
            text={'Add New Event'}
            icon={<PlusIcon fill="#081120" />}
            className="bg-elr-white-400 text-elr-black rounded-sm py-3 px-5"
          />
        </div>
      </section>
      <section className="px-8 py-4 flex justify-between bg-elr-white-400 mb-5">
        <div className="flex items-center">
          <p>{event.name} &nbsp; - &nbsp;</p>
          <div className="bg-elr-gray py-2 px-3">
            <p className="text-elr-purple">{eventLink}</p>
          </div>
        </div>
        <div className="flex gap-x-4">
          {!eventIsClosed ? (
            <ElrButton
              text={'Turn off Event'}
              disabled={isLoading}
              loading={isLoading}
              onClick={handleCloseEvent}
              className="bg-elr-gray text-elr-error rounded-sm py-3 px-5"
            />
          ) : (
            <ElrButton
              text={'Event Closed'}
              disabled
              onClick={handleCloseEvent}
              className="bg-elr-gray text-success rounded-sm py-3 px-5"
            />
          )}
          <ElrButtonPreIcon
            text={'Copy Event Link'}
            icon={<CopyDoubleIcon />}
            onClick={handleCopy}
            className="bg-elr-black text-elr-white-400 rounded-sm py-3 px-5"
          />
        </div>
      </section>
      <section className="grid grid-cols-column-two gap-4 mb-5">
        <div className="bg-elr-white-400 py-5 px-6">
          <div>
            <div className="float-end">
              <ElrButton
                text={'Withdraw'}
                disabled={isLoading}
                loading={isLoading}
                className="bg-elr-black text-elr-white-400 rounded-sm py-3 px-5"
              />
            </div>
            <MoneyIcon />
            <div className="mb-5" />
            <p className="text-lg text-elr-black-300 leading-5 mb-3">
              Total Sales
            </p>
            <p className="text-3xl text-elr-black">
              {currencyFormatter(calculateTotalSalesAmount(ticketSales))}
            </p>
          </div>
        </div>
        <div className="bg-elr-white-400 py-5 px-6">
          <div>
            <DrawerIcon />
            <div className="mb-5" />
            <p className="text-lg text-elr-black-300 leading-5 mb-3">
              Total Tickets Sold
            </p>
            <p className="text-3xl text-elr-black">
              {calculateTotalTicketsSold(ticketSales)}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-elr-white-400 py-5 px-8 mb-5">
        <h3>Event Tickets</h3>
        <div className="mt-4 flex gap-x-4">
          {event.tickets.map((ticket) => (
            <div key={ticket.id} className="bg-elr-gray py-4 px-5 flex">
              <div>
                <p>{ticket.type.toLocaleUpperCase()}</p>
                <p className="text-elr-black-300">N{ticket.price}</p>
                <p className="text-elr-black-300">
                  Quantity Sold - {ticket.quantity}
                </p>
                <p className="text-elr-black-300">
                  Sales Deadline - {formatExpiryDate(ticket.expiryDate)}
                </p>
              </div>
              <div className="self-center">
                <ElrSwitch
                  isToggled={ticket.active}
                  toggle={() => handleToggleTicket(ticket.type, !ticket.active)}
                  parentClassName="bg-elr-white-400"
                  circleClassName="bg-elr-black"
                  toggledCircleClassName="bg-elr-white-400"
                  toggledParentClassName="!bg-elr-black"
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-elr-white-400">
        <div className="py-4 px-8 grid grid-cols-column-two">
          <h3 className="self-center">Ticket Sales Breakdown - First(100)</h3>
          <div className="flex justify-between gap-2">
            <ElrDropdownButton
              text="All Tickets"
              isToggled={false}
              className="bg-elr-gray rounded-sm !w-1/2"
              size="lg"
            />
            <ElrButtonPreIcon
              text={'View Event Info'}
              icon={<InformationIcon />}
              className="bg-elr-gray text-elr-black w-1/2 rounded-sm py-3 px-5"
            />
          </div>
        </div>
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr className="border border-x-0 border-elr-gray">
                <th className="py-5">Attendee</th>
                <th>Ticket Type</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {soldTickets.map((ticket) => (
                <tr
                  key={ticket.id}
                  className="text-center border border-x-0 border-elr-gray"
                >
                  <td className="py-5">
                    {ticket.firstname} {ticket.lastname}
                  </td>
                  <td>{ticket.type}</td>
                  <td>{ticket.quantity}</td>
                  <td>{ticket.totalAmount}</td>
                  <td>{new Date(ticket.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default EventDashboard;
